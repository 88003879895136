// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.png";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
// Porject Images
import projectImage1 from "./assets/projects/project1.jpg";
import projectImage2 from "./assets/projects/project2.png";
import projectImage3 from "./assets/projects/project3.png";
import projectImage4 from "./assets/projects/project4.png";
import projectImage5 from "./assets/projects/Screenshot (223).png";
import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Murshid",
  tagline: "I build things for web and mobile.",
  img: profile,
  about: `With hands-on experience in crafting intuitive and responsive web applications, I specialize in frontend development and UI/UX design. My skill set includes HTML, CSS, JavaScript, and modern frameworks like React.js. I'm adept at creating visually appealing and user-friendly interfaces, ensuring seamless user experiences across all devices.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/muhammed-murshid-4316a9230/",
  github: "https://github.com/onlyformurshi",
  twitter: "https://x.com/onlyformurshi",
  instagram: "https://www.instagram.com/onlyformurshi",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Frontend Web Developer",
    Company: `Workmate Infotech Pvt Ltd`,
    Location: "Calicut Kinfra Techno Park",
    Type: "Full Time",
    Duration: "Nov-2023 - Present",
  },
  {
    Position: "Internship",
    Company: `Skilz Hub`,
    Location: "Kottakkal,Malappuram",
    Type: "Internship",
    Duration: "Jun 2023 - Nov 2023",
  },

];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Ui/Ux with Web Designer",
    Company: "Skilz Hub Kottakkal",
    Location: "Online",
    Type: " T.V Complex, 4th Floor Changuvetty N.H, Kottakkal, Kerala 676501",
    Duration: "Jun 2023 - Nov 2023",
  },
  {
    Position: "Bachelor in Information Technology",
    Company: `Calicut University`,
    Location: "Manjeri",
    Type: "Bachelor",
    Duration: "Aug 2020 - Jun-2023",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
  

};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Tomoto App",
    image: projectImage1,
    description: `Developed a comprehensive food delivery application named ”Tomato,”
designed to provide a seamless and intuitive user experience for customers
ordering food online. The application features a dynamic Home page to browse
menus and food items, a Cart page for managing selected items, an Order page
for finalizing purchases, and a Sign In / Sign Up popup for user authentication..`,
    techstack: "React Js,Css,Bootstrap,Apis",
    previewLink: "https://heroic-bunny-9f7312.netlify.app/",
    githubLink: "https://github.com/onlyformurshi/tomoto-app",
  },

  {
    title: "Net Movies",
    image: projectImage2,
    description: `I developed a Netflix clone web application using React.js, CSS, Bootstrap, and TMDB APIs. This project replicates the core functionality and user interface of Netflix, featuring a sleek and responsive design. The app allows users to browse and search for movies and TV shows, view detailed information about each title, and play trailers. The integration with TMDB APIs enables dynamic content updates, ensuring that the app displays the latest trending movies and shows. This project highlights my skills in frontend development, API integration, and responsive design.`,
    techstack: "React Js,Css,Bootstrap,TMDB Apis",
    previewLink: "https://netmoviesmurshiddev.netlify.app/",
    githubLink: "https://github.com/onlyformurshi/Net-Movies",
  },
  {
    title: "Workmate Infotech Pvt Ltd - Company Website",
    image: projectImage3,
    description: `I designed and developed the official website for Workmate Infotech Pvt Ltd using HTML, CSS, JavaScript, and Bootstrap. The website showcases our company's services, portfolio, and contact information through a clean, modern, and responsive design. Leveraging Bootstrap, I ensured the site is fully responsive across all devices, providing a seamless user experience. This project demonstrates my expertise in front-end web development, UI/UX design, and the ability to create professional business websites.`,
    techstack: "HTML/CSS, JavaScript, Bootstrap",
    previewLink: "https://workmateinfotech.com/",
    githubLink: "https://github.com",
  },
  {
    title: "Chat Application",
    image: projectImage5,
    description: `I developed a real-time chat application using React.js, CSS, JavaScript, Bootstrap, and npm. The application features a modern and intuitive interface, enabling users to send and receive messages seamlessly. With Bootstrap, the design is fully responsive, ensuring an optimal user experience across all devices. This project showcases my ability to build interactive, real-time applications with a strong focus on user experience and front-end development.`,
    techstack: "React Js ,Css, JavaScript, Bootstrap, npm",
    previewLink: "https://chat-app-jade-ten.vercel.app/",
    githubLink: "https://github.com/onlyformurshi/chat-app",
  },
  {
    title: "Shade Charity Project",
    image: projectImage4,
    description: `The Shade Charity App is a dedicated platform designed to support dialysis
patients through various charitable initiatives Key features include:
• Participate in the Challenge: Users can order biriyani as part of a fundraising
challenge, combining culinary enjoyment with a cause Donate Directly: With just a few taps, users can contribute directly to causes
supported by the Shade Charity Cell, making a meaningful impact in the lives of
those in need.`
    ,
    techstack: "Figma,Photoshop,HTML/CSS,JavaScript,Bootstrap,Php,Mysql",
    previewLink: "https://play.google.com/store/apps/details?id=com.shadekadappadi.moneypool&hl=en",
    githubLink: "https://github.com",
  },
  {
    title: "ERP Application",
    image: projectImage6,
    description: `I designed an ERP application tailored for a construction company using Figma and Photoshop. This application streamlines the management of various construction processes, including project planning, resource allocation, and financial tracking. The design focuses on user-friendly interfaces and clear, intuitive workflows, ensuring that complex tasks are simplified for the end-users. This project highlights my proficiency in UI/UX design and my ability to create efficient digital solutions for industry-specific needs.`,
    techstack: "Figma,Photoshop",
    previewLink: "",
    githubLink: "https://github.com",
  },

];

// Enter your Contact Details here
export const contactDetails = {
  email: "onlyformurshi@gmail.com",
  phone: "+91 9567504544",
};
